// context/NavigationContext.js
/*import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const NavigationContext = createContext();

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const [history, setHistory] = useState(["Landing"]);
  const [selections, setSelections] = useState({});
  const [pageData, setPageData] = useState({});
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [dob, setDob] = useState("");
  const [status, setStatus] = useState("");
  const [action, setAction] = useState("");
  const [confirm, setConfirm] = useState(false);
  const navigateTo = (page, status) => {
    if (page === "back") {
      setSelections((a) => {
        return Object.fromEntries(
          Object.entries(a).filter(([key, value]) => value !== null)
        );
      });
      setHistory((prev) => prev.slice(0, -1));
      navigate(-1);
    } else {
      setHistory((prev) => [...prev, page]);
      if (page === "verifyPage") {
        navigate("/verify");
      } else if (page === "DecisionPage") {
        navigate("/create/decision", { state: { status } });
      } else {
        navigate(`/create/${page}`);
      }
    }
  };

  const saveSelection = (page, option) => {
    setSelections((prev) => ({ ...prev, [page]: option }));
  };

  const resetAllState = () => {
    setSelections({});
    setPageData({});
    setHistory(["Landing"]);
  };

  const setPageInfo = (page, data) => {
    setPageData((prev) => {
      const currentPageData = prev[page] || {};
      if (JSON.stringify(currentPageData) !== JSON.stringify(data)) {
        return { ...prev, [page]: data };
      }
      return prev;
    });
  };

  const getPageInfo = (page) => pageData[page] || {};

  return (
    <NavigationContext.Provider
      value={{
        history,
        navigateTo,
        saveSelection,
        selections,
        setSelections,
        resetAllState,
        setPageInfo,
        getPageInfo,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        dob,
        setDob,
        day,
        setDay,
        month,
        setMonth,
        year,
        setYear,
        email,
        setEmail,
        status,
        setStatus,
        action,
        setAction,
        confirm,
        setConfirm,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};*/

// context/NavigationContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NavigationContext = createContext();

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();

  // Helper function to safely parse JSON from sessionStorage
  const loadFromSessionStorage = (key, defaultValue) => {
    try {
      const storedValue = sessionStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    } catch (error) {
      console.error(`Error parsing ${key} from sessionStorage`, error);
      return defaultValue;
    }
  };

  // Initialize state from sessionStorage or with default values
  const [history, setHistory] = useState(() =>
    loadFromSessionStorage("history", ["Landing"])
  );
  const [selections, setSelections] = useState(() =>
    loadFromSessionStorage("selections", {})
  );
  const [pageData, setPageData] = useState(() =>
    loadFromSessionStorage("pageData", {})
  );

  // Update sessionStorage whenever state changes
  useEffect(() => {
    sessionStorage.setItem("history", JSON.stringify(history));
  }, [history]);

  useEffect(() => {
    sessionStorage.setItem("selections", JSON.stringify(selections));
  }, [selections]);

  useEffect(() => {
    sessionStorage.setItem("pageData", JSON.stringify(pageData));
  }, [pageData]);

  const navigateTo = (page, status) => {
    if (page === "back") {
      setSelections((a) => {
        return Object.fromEntries(
          Object.entries(a).filter(([key, value]) => value !== null)
        );
      });
      setHistory((prev) => prev.slice(0, -1));
      navigate(-1);
    } else {
      setHistory((prev) => [...prev, page]);
      if (page === "verifyPage") {
        navigate("/verify");
      } else if (page === "DecisionPage") {
        navigate("/create/decision", { state: { status } });
      } else {
        navigate(`/create/${page}`);
      }
    }
  };

  const saveSelection = (page, option) => {
    setSelections((prev) => ({ ...prev, [page]: option }));
  };

  const resetAllState = () => {
    setSelections({});
    setPageData({});
    setHistory(["Landing"]);
    sessionStorage.removeItem("history");
    sessionStorage.removeItem("selections");
    sessionStorage.removeItem("pageData");
  };

  const setPageInfo = (page, data) => {
    setPageData((prev) => {
      const currentPageData = prev[page] || {};
      if (JSON.stringify(currentPageData) !== JSON.stringify(data)) {
        return { ...prev, [page]: data };
      }
      return prev;
    });
  };

  const getPageInfo = (page) => pageData[page] || {};

  return (
    <NavigationContext.Provider
      value={{
        history,
        navigateTo,
        saveSelection,
        selections,
        setSelections,
        resetAllState,
        setPageInfo,
        getPageInfo,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
