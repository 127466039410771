import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useNavigation } from "../context/NavigationContext";
import "../styles/main.css";

const LandingPage = () => {
  const { navigateTo } = useNavigation();

  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate(0);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return (
    <div className="landing-page">
      <Header />
      <div className="landing-content-sharecode">
        <h1>IRESHA Sharecode</h1>
        <h3>
          Immigration/Residence Status Eligibility for Social Housing Assistance
          - Sharecode
        </h3>
        <div className="intro-text">
          <p>
            This webapp can be used to generate a sharecode indicating that you
            fulfil the minimum immigration/residence status sigibility
            requirements for social-housing assistance{" "}
          </p>
          <h4>For applicants</h4>
          To create a sharecode, you will be asked a series of questions to
          check if you have the minimum eligiblity for social-housing
          assistance.
          <br></br>
          <br></br>
          There might be additional requirements based on where you live or
          where you want to apply for social housing.
          <p></p>
          <button id="nextButton" onClick={() => navigateTo("createPage1")}>
            Check eligibility and create sharecode
          </button>
          <h4>For housing associations, local authorities or caseworkers</h4>
          Use this service if you want to verify a sharecode to check someone's
          minimum eligibility for social housing
          <p></p>
          <button id="nextButton" onClick={() => navigateTo("verifyPage")}>
            Verify sharecode
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
