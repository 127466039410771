import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigation } from "../context/NavigationContext";
import Header from "../components/Header";
import { pageConfig } from "../content/pageConfig";
import "../styles/Choicepage.css";
import HelpTooltip from "../components/HelpTooltip";
import TextDisplay from "../components/TextDisplay";
import { useGlobalState } from "../context/GlobalStateContext";

const ChoicePage = () => {
  const { pageId } = useParams();
  const content = pageConfig[pageId];
  const { heading, subtitle, is_multi, options, textInput, reasoning } =
    content || {};
  const { navigateTo, saveSelection, setPageInfo, getPageInfo } =
    useNavigation();
  const { status, setStatus, setBrp } = useGlobalState();
  const is_identity_page = content?.identitypage;
  const savedData = getPageInfo(pageId);
  const [selectedOptions, setSelectedOptions] = useState(
    savedData?.selectedOptions || (is_multi ? [] : null)
  );
  const [textInputValue, setTextInputValue] = useState(
    savedData?.textInputValue || ""
  );

  useEffect(() => {
    if (savedData) {
      setSelectedOptions(savedData.selectedOptions || (is_multi ? [] : null));
      setTextInputValue(savedData.textInputValue || "");
    }
  }, [pageId]);

  useEffect(() => {
    setPageInfo(pageId, { selectedOptions, textInputValue });
  }, [selectedOptions, textInputValue, setPageInfo, pageId]);

  const handleSelect = (optionValue) => {
    if (is_multi) {
      setSelectedOptions((prev) =>
        prev.includes(optionValue)
          ? prev.filter((val) => val !== optionValue)
          : [...prev, optionValue]
      );
    } else {
      setSelectedOptions(optionValue);
    }
  };

  const handleBack = () => {
    saveSelection(pageId, null);
    navigateTo("back");
  };

  const handleNext = () => {
    saveSelection(pageId, selectedOptions);
    const selectedOption = options.find((opt) => opt.value === selectedOptions);
    const nextPage = selectedOption?.next;

    if (is_identity_page && is_identity_page === true) {
      const isBrp = selectedOption?.brp;

      if (isBrp && isBrp === true) {
        setBrp(true);
      } else {
        setBrp(false);
      }
    }
    //const status = selectedOption?.status;
    setStatus(selectedOption?.status);

    if (nextPage === "DecisionPage" && status) {
      navigateTo("DecisionPage", status);
    } else if (nextPage) {
      navigateTo(nextPage);
    }
  };

  return (
    <div className="container">
      <Header />
      <h1>{heading}</h1>
      <p>{subtitle}</p>

      {options && options.length > 0 && (
        <div className="options-container">
          {options.map((option) => (
            <div key={option.value} className="option">
              <label>
                <input
                  type={is_multi ? "checkbox" : "radio"}
                  name="options"
                  value={option.value}
                  checked={
                    is_multi
                      ? selectedOptions.includes(option.value)
                      : selectedOptions === option.value
                  }
                  onChange={() => handleSelect(option.value)}
                />
                {option.text}
                {option.tooltiptext && (
                  <HelpTooltip sections={option.tooltiptext} />
                )}
              </label>
            </div>
          ))}
        </div>
      )}

      {textInput && (
        <div className="text-input-container">
          <label>
            {textInput.label && <span>{textInput.label}</span>}
            <input
              type="text"
              placeholder={textInput.placeholder || "Enter text..."}
              value={textInputValue}
              onChange={(e) => setTextInputValue(e.target.value)}
            />
          </label>
        </div>
      )}

      <div className="button-container">
        <button id="backButton" onClick={handleBack}>
          Back
        </button>
        <button
          id="nextButton"
          onClick={handleNext}
          disabled={
            (options && selectedOptions === null) ||
            (textInput && textInputValue === "")
          }
        >
          Next
        </button>
      </div>
      {reasoning && <TextDisplay sections={reasoning} pageId={pageId} />}
    </div>
  );
};

export default ChoicePage;
