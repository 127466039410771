// pages/ShareCodeFormPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import LoadingSpinner from "../components/LoadingSpinner";
import "../styles/Choicepage.css";
import "../styles/main.css";
import { useGlobalState } from "../context/GlobalStateContext";

const ShareCodeFormPage = () => {
  const navigate = useNavigate();
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    day,
    setDay,
    year,
    setYear,
    month,
    setMonth,
    confirm,
    setConfirm,
    action,
    brp,
    step,
    setStep,
    brpInput,
    setBrpInput,
  } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [brpError, setBrpError] = useState("");
  const dummyBrp = ["ZU1234567", "ZU5671234", "ZU9991234"];

  /*useEffect(() => {
    const handleBackNavigation = (event) => {
      event.preventDefault();
      if (step > 1) {
        setStep((prevStep) => (prevStep === 4 && brp ? 3 : prevStep - 1));
      } else {
        navigate(-1);
      }
    };

    window.addEventListener("popstate", handleBackNavigation);

    return () => {
      window.removeEventListener("popstate", handleBackNavigation);
    };
  }, [step, navigate, brp]);*/

  const isDateValid = () => {
    const date = new Date(`${year}-${month}-${day}`);
    return (
      date instanceof Date &&
      !isNaN(date) &&
      date.getDate() === parseInt(day) &&
      date.getMonth() + 1 === parseInt(month) &&
      date.getFullYear() === parseInt(year)
    );
  };

  const handleNext = () => {
    if (step === 2 && !isDateValid()) {
      alert("Please enter a valid date.");
      return;
    }
    if (step === 4 && email !== confirmEmail) {
      alert(
        "Email and Confirm Email do not match. Please check and try again."
      );
      return;
    }
    if (step === 2) {
      setStep(brp ? 3 : 4);
    } else if (step === 3 && brp) {
      if (!dummyBrp.includes(brpInput)) {
        setBrpError(
          "The BRP number entered does not match our records. Make sure you enter your details exactly as they appear on your biometric residence card or permit."
        );
        return;
      }
      setBrpError("");
      setStep(4);
    } else {
      setStep(step + 1);
    }
    window.history.pushState(null, "", `?step=${step + 1}`);
  };

  const handleBack = () => {
    if (step > 1) {
      const prevStep = step === 4 && !brp ? 2 : step - 1;
      setStep(prevStep);
      //window.history.pushState(null, "", `?step=${prevStep}`);
    } else {
      navigate("/create/decision");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://tough-jazmin-primepersonacreator-c22863b8.koyeb.app/request-otp",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: email, action: action }),
        }
      );

      setLoading(false);
      if (response.status === 200) {
        navigate("/enterotp");
      } else {
        navigate("/error", {
          state: {
            message: "We could not verify the OTP. Please try again",
            type: "otp",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      navigate("/error", {
        state: {
          message:
            "We could not send an OTP to given email. Kindly recheck your information before proceeding",
          type: "otp",
        },
      });
    }
  };

  return (
    <div className="container">
      <Header />
      {loading ? (
        <LoadingSpinner text="Requesting OTP..." />
      ) : (
        <>
          {/* Step 1 - Name Input */}
          {step === 1 && (
            <>
              <h1>Enter your information</h1>
              <br />
              <br />
              <label>
                <strong>What is your first name?</strong>
              </label>
              <label>
                This should be the same as on your identity document.
              </label>
              <br />
              <input
                type="text"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <br />
              <br />
              <label>
                <strong>What is your last name?</strong>
              </label>
              <label>
                This should be the same as on your identity document.
              </label>
              <br />
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <br />
              <br />
              <div className="button-container">
                <button id="backButton" onClick={handleBack}>
                  Back
                </button>
                <button
                  id="nextButton"
                  onClick={handleNext}
                  disabled={firstName === "" || lastName === ""}
                >
                  Next
                </button>
              </div>
            </>
          )}

          {/* Step 2 - Date of Birth Input */}
          {step === 2 && (
            <>
              <h1>Enter your information</h1>
              <br />
              <br />
              <label>
                <strong>What is your date of birth?</strong>
              </label>
              <label>For example 31 3 1980</label>
              <br />
              <span className="date-picker">
                <input
                  className="day-picker"
                  type="text"
                  name="day"
                  placeholder="DD"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                />
                <label></label>
                <input
                  className="month-picker"
                  type="text"
                  name="month"
                  placeholder="MM"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                />
                <label></label>
                <input
                  className="year-picker"
                  type="text"
                  name="year"
                  placeholder="YYYY"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                />
              </span>
              <br />
              <br />
              <div className="button-container">
                <button id="backButton" onClick={handleBack}>
                  Back
                </button>
                <button
                  id="nextButton"
                  onClick={handleNext}
                  disabled={day === "" || month === "" || year === ""}
                >
                  Next
                </button>
              </div>
            </>
          )}

          {/* Step 3 - BRP Input (Conditional) */}
          {step === 3 && brp && (
            <>
              <h1>Enter your information</h1>
              <br></br>
              <br></br>
              <label>
                <strong>
                  What is your biometric residence card or permit number?
                </strong>
              </label>
              <label>For example, ZU1234567</label>
              <br />
              <input
                type="text"
                name="brpInput"
                value={brpInput}
                onChange={(e) => setBrpInput(e.target.value)}
              />
              {brpError && <p className="error-message">{brpError}</p>} <br />
              <br />
              <div className="button-container">
                <button id="backButton" onClick={handleBack}>
                  Back
                </button>
                <button
                  id="nextButton"
                  onClick={handleNext}
                  disabled={brpInput === ""}
                >
                  Next
                </button>
              </div>
            </>
          )}

          {/* Step 4 - Email Input */}
          {step === 4 && (
            <>
              <h1>Enter your information</h1>
              <br />
              <br />
              <label>
                <strong>What is your email address?</strong>
              </label>
              <br />
              <input
                type="text"
                name="email"
                placeholder="user@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />
              <label>
                <strong>Retype your email address here</strong>
              </label>
              <br />
              <input
                type="text"
                name="confirmEmail"
                value={confirmEmail}
                onChange={(e) => setConfirmEmail(e.target.value)}
              />
              <br />
              <div className="button-container">
                <button id="backButton" onClick={handleBack}>
                  Back
                </button>
                <button
                  id="nextButton"
                  onClick={handleNext}
                  disabled={email === "" || confirmEmail === ""}
                >
                  Next
                </button>
              </div>
            </>
          )}

          {/* Step 5 - Confirmation Step */}
          {step === 5 && (
            <>
              <h1>You gave us the following information about yourself</h1>
              <p>
                <strong>Your first name: </strong>
                {firstName}
              </p>
              <p>
                <strong>Your last name: </strong>
                {lastName}
              </p>
              <p>
                <strong>Your date of birth: </strong>
                {day}/{month}/{year}
              </p>
              <p>
                <strong>Your email: </strong>
                {email}
              </p>
              {brp && (
                <p>
                  <strong>Your BRP: </strong>
                  {brpInput}
                </p>
              )}
              <label>
                <input
                  type="checkbox"
                  name="confirm"
                  checked={confirm}
                  onChange={(e) => setConfirm(!confirm)}
                />
                I confirm that this information is correct to the best of my
                knowledge
              </label>
              <br />
              <div className="button-container">
                <button id="backButton" onClick={handleBack}>
                  Back
                </button>
                <button
                  id="nextButton"
                  onClick={handleSubmit}
                  disabled={!confirm}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ShareCodeFormPage;
