import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import "../styles/Choicepage.css";

const ErrorPage = () => {
  const { state } = useLocation();
  const { message } = state || { message: "An error occurred" };
  const { type } = state || "";
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/");
  };
  const handleBack = () => {
    if (type === "otp_verify_failed") {
      navigate("/sharecodeform");
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="container-error-page">
      <Header />
      <h1>Error</h1>
      <p dangerouslySetInnerHTML={{ __html: message }}></p>
      <div className="button-container">
        <button id="backButton" onClick={handleBack}>
          Back
        </button>
        {type !== "otp" && (
          <button id="nextButton" onClick={handleNext}>
            Start Over
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
