/*// context/GlobalStateContext.js
import React, { createContext, useContext, useState } from "react";

// Create the context
const GlobalStateContext = createContext();

// Custom hook to access the global state and functions
export const useGlobalState = () => useContext(GlobalStateContext);

// Provider component for managing individual state variables
export const GlobalStateProvider = ({ children }) => {
  // Individual states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [action, setAction] = useState("creation");
  const [confirm, setConfirm] = useState(false);
  const [brp, setBrp] = useState(false);
  const [brpInput, setBrpInput] = useState("");

  const [step, setStep] = useState(1);

  // Reset function to reset each state individually
  const resetAllFields = () => {
    setFirstName("");
    setLastName("");
    setDay("");
    setMonth("");
    setYear("");
    setDob("");
    setEmail("");
    setStatus("");
    setAction("");
    setConfirm(false);
    setBrp(false);
    setStep(1);
    setBrpInput("");
  };

  return (
    <GlobalStateContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        day,
        setDay,
        month,
        setMonth,
        year,
        setYear,
        dob,
        setDob,
        email,
        setEmail,
        status,
        setStatus,
        action,
        setAction,
        confirm,
        setConfirm,

        step,
        setStep,
        brp,
        setBrp,
        brpInput,
        setBrpInput,

        resetAllFields, // Export the reset function
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};*/

// context/GlobalStateContext.js
/*import React, { createContext, useContext, useState, useEffect } from "react";

// Create the context
const GlobalStateContext = createContext();

// Custom hook to access the global state and functions
export const useGlobalState = () => useContext(GlobalStateContext);

// Provider component for managing individual state variables
export const GlobalStateProvider = ({ children }) => {
  // Function to load state from sessionStorage or use default if not found
  const loadFromSession = (key, defaultValue) =>
    JSON.parse(sessionStorage.getItem(key)) || defaultValue;

  // Individual states
  const [firstName, setFirstName] = useState(() =>
    loadFromSession("firstName", "")
  );
  const [lastName, setLastName] = useState(() =>
    loadFromSession("lastName", "")
  );
  const [day, setDay] = useState(() => loadFromSession("day", ""));
  const [month, setMonth] = useState(() => loadFromSession("month", ""));
  const [year, setYear] = useState(() => loadFromSession("year", ""));
  const [dob, setDob] = useState(() => loadFromSession("dob", ""));
  const [email, setEmail] = useState(() => loadFromSession("email", ""));
  const [status, setStatus] = useState(() => loadFromSession("status", ""));
  const [action, setAction] = useState(() =>
    loadFromSession("action", "creation")
  );
  const [confirm, setConfirm] = useState(() =>
    loadFromSession("confirm", false)
  );
  const [brp, setBrp] = useState(() => loadFromSession("brp", false));
  const [brpInput, setBrpInput] = useState(() =>
    loadFromSession("brpInput", "")
  );
  const [step, setStep] = useState(() => loadFromSession("step", 1));

  // Update sessionStorage whenever any state changes
  useEffect(() => {
    sessionStorage.setItem("firstName", JSON.stringify(firstName));
  }, [firstName]);

  useEffect(() => {
    sessionStorage.setItem("lastName", JSON.stringify(lastName));
  }, [lastName]);

  useEffect(() => {
    sessionStorage.setItem("day", JSON.stringify(day));
  }, [day]);

  useEffect(() => {
    sessionStorage.setItem("month", JSON.stringify(month));
  }, [month]);

  useEffect(() => {
    sessionStorage.setItem("year", JSON.stringify(year));
  }, [year]);

  useEffect(() => {
    sessionStorage.setItem("dob", JSON.stringify(dob));
  }, [dob]);

  useEffect(() => {
    sessionStorage.setItem("email", JSON.stringify(email));
  }, [email]);

  useEffect(() => {
    sessionStorage.setItem("status", JSON.stringify(status));
  }, [status]);

  useEffect(() => {
    sessionStorage.setItem("action", JSON.stringify(action));
  }, [action]);

  useEffect(() => {
    sessionStorage.setItem("confirm", JSON.stringify(confirm));
  }, [confirm]);

  useEffect(() => {
    sessionStorage.setItem("brp", JSON.stringify(brp));
  }, [brp]);

  useEffect(() => {
    sessionStorage.setItem("brpInput", JSON.stringify(brpInput));
  }, [brpInput]);

  useEffect(() => {
    sessionStorage.setItem("step", JSON.stringify(step));
  }, [step]);

  // Reset function to reset each state individually and clear sessionStorage
  const resetAllFields = () => {
    setFirstName("");
    setLastName("");
    setDay("");
    setMonth("");
    setYear("");
    setDob("");
    setEmail("");
    setStatus("");
    setAction("");
    setConfirm(false);
    setBrp(false);
    setBrpInput("");
    setStep(1);

    // Clear sessionStorage
    sessionStorage.clear();
  };

  return (
    <GlobalStateContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        day,
        setDay,
        month,
        setMonth,
        year,
        setYear,
        dob,
        setDob,
        email,
        setEmail,
        status,
        setStatus,
        action,
        setAction,
        confirm,
        setConfirm,
        step,
        setStep,
        brp,
        setBrp,
        brpInput,
        setBrpInput,
        resetAllFields, // Export the reset function
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
*/

// context/GlobalStateContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const GlobalStateContext = createContext();

export const useGlobalState = () => useContext(GlobalStateContext);

export const GlobalStateProvider = ({ children }) => {
  // Helper function to safely parse JSON from sessionStorage
  const loadFromSessionStorage = (key, defaultValue) => {
    try {
      const storedValue = sessionStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    } catch (error) {
      console.error(`Error parsing ${key} from sessionStorage`, error);
      return defaultValue;
    }
  };

  // Initialize states from sessionStorage or use default values
  const [firstName, setFirstName] = useState(() =>
    loadFromSessionStorage("firstName", "")
  );
  const [lastName, setLastName] = useState(() =>
    loadFromSessionStorage("lastName", "")
  );
  const [day, setDay] = useState(() => loadFromSessionStorage("day", ""));
  const [month, setMonth] = useState(() => loadFromSessionStorage("month", ""));
  const [year, setYear] = useState(() => loadFromSessionStorage("year", ""));
  const [dob, setDob] = useState(() => loadFromSessionStorage("dob", ""));
  const [email, setEmail] = useState(() => loadFromSessionStorage("email", ""));
  const [status, setStatus] = useState(() =>
    loadFromSessionStorage("status", "")
  );
  const [action, setAction] = useState(() =>
    loadFromSessionStorage("action", "creation")
  );
  const [confirm, setConfirm] = useState(() =>
    loadFromSessionStorage("confirm", false)
  );
  const [brp, setBrp] = useState(() => loadFromSessionStorage("brp", false));
  const [brpInput, setBrpInput] = useState(() =>
    loadFromSessionStorage("brpInput", "")
  );
  const [step, setStep] = useState(() => loadFromSessionStorage("step", 1));

  // Update sessionStorage whenever each state changes
  useEffect(() => {
    sessionStorage.setItem("firstName", JSON.stringify(firstName));
  }, [firstName]);

  useEffect(() => {
    sessionStorage.setItem("lastName", JSON.stringify(lastName));
  }, [lastName]);

  useEffect(() => {
    sessionStorage.setItem("day", JSON.stringify(day));
  }, [day]);

  useEffect(() => {
    sessionStorage.setItem("month", JSON.stringify(month));
  }, [month]);

  useEffect(() => {
    sessionStorage.setItem("year", JSON.stringify(year));
  }, [year]);

  useEffect(() => {
    sessionStorage.setItem("dob", JSON.stringify(dob));
  }, [dob]);

  useEffect(() => {
    sessionStorage.setItem("email", JSON.stringify(email));
  }, [email]);

  useEffect(() => {
    sessionStorage.setItem("status", JSON.stringify(status));
  }, [status]);

  useEffect(() => {
    sessionStorage.setItem("action", JSON.stringify(action));
  }, [action]);

  useEffect(() => {
    sessionStorage.setItem("confirm", JSON.stringify(confirm));
  }, [confirm]);

  useEffect(() => {
    sessionStorage.setItem("brp", JSON.stringify(brp));
  }, [brp]);

  useEffect(() => {
    sessionStorage.setItem("brpInput", JSON.stringify(brpInput));
  }, [brpInput]);

  useEffect(() => {
    sessionStorage.setItem("step", JSON.stringify(step));
  }, [step]);

  // Reset all fields and clear sessionStorage
  const resetAllFields = () => {
    setFirstName("");
    setLastName("");
    setDay("");
    setMonth("");
    setYear("");
    setDob("");
    setEmail("");
    setStatus("");
    setAction("creation");
    setConfirm(false);
    setBrp(false);
    setBrpInput("");
    setStep(1);
    sessionStorage.clear();
  };

  return (
    <GlobalStateContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        day,
        setDay,
        month,
        setMonth,
        year,
        setYear,
        dob,
        setDob,
        email,
        setEmail,
        status,
        setStatus,
        action,
        setAction,
        confirm,
        setConfirm,
        brp,
        setBrp,
        brpInput,
        setBrpInput,
        step,
        setStep,
        resetAllFields,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
